import { Grid } from "@mui/material";
import { HeadFC } from "gatsby";
import * as React from "react";
import { MainFrame } from "../components/MainFrame";
import { Pager } from "../components/Pager";
import { PostCard } from "../components/PostCard";
import "../styles/styles.scss";
import { PageContext } from "./interfaces";

const YearsPage = ({
  data,
  pageContext
}: {
  data: any;
  pageContext: PageContext;
}) => {
  const content = pageContext.posts.map((value, index) => {
    return (
      <PostCard
        key={`post-${index}`}
        blogsId={value.blogsId}
        eyecatch={value.eyecatch}
        title={value.title}
        content={value.content}
        tags={value.tags}
        publishedAt={value.publishedAt}
        forceView={index < 6}
      />
    );
  });

  return (
    <MainFrame type="website" subtitle={`Year: ${pageContext.archiveTitle}`}>
      <h2>Year: {pageContext.archiveTitle}</h2>
      <div className="content">
        <Grid container spacing={2}>
          {content}
        </Grid>
        <Grid container>
          <Grid item xs></Grid>
          <Grid item xs="auto">
            <Pager
              archiveName={pageContext.archiveName}
              currentPage={pageContext.currentPage}
              totalPages={pageContext.totalPages}
            />
          </Grid>
          <Grid item xs></Grid>
        </Grid>
      </div>
    </MainFrame>
  );
};

export default YearsPage;

export const Head: HeadFC = () => <></>;
